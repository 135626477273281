<template lang="pug">
    section#destaques
        .wrapper
            .controls
                button.left(@click="changeDestaque(ativo - 1)")
                    SvgIcon(data="@svgs/seta-ippuc.svg")
                button.right(@click="changeDestaque(ativo + 1)")
                    SvgIcon(data="@svgs/seta-ippuc.svg")
            ul.destaques
                li.destaque(v-for="noticia, index in noticias" :class="{'ativo' : index == ativo}")
                    router-link(:to="{name: 'Noticias'}")
                        .img
                            img(:src="noticia.img")
                        .tag
                            p {{noticia.tag}}
                        .info
                            FontAwesomeIcon(:icon="icons.faShareNodes")
                            h3 {{noticia.titulo}}
                            span {{noticia.date}}
                            p {{noticia.description}}
</template>

<script>
import { faShareNodes } from '@fortawesome/free-solid-svg-icons'

export default {
  name: 'section-destaques',
  data () {
    return {
      icons: {
        faShareNodes
      },
      ativo: 0,
      noticias: [
        {
          img: require('@images/temp/noticias-temp.png'),
          tag: 'tecnologia',
          titulo: 'News Title 1',
          date: '12 Setembro 2021',
          description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint '
        },
        {
          img: require('@images/temp/noticias-temp.png'),
          tag: 'tecnologia',
          titulo: 'News Title 2',
          date: '12 Setembro 2021',
          description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint '
        }
      ]
    }
  },
  methods: {
    changeDestaque (index) {
      if (index < 0) { this.ativo = this.noticias.length - 1 } else if (index > this.noticias.length - 1) { this.ativo = 0 } else { this.ativo = index }
    }
  }
}
</script>

<style lang="stylus" scoped src="./GaleriaDestaques.styl"></style>
